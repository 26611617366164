@import 'partials';

$breakpoints: (xs: (columns: 4, exclusive_query: "all and (max-width: 767px)", query: "all"), s: (columns: 6, exclusive_query: "(min-width: 768px) and (max-width: 991px)", query: "(min-width: 768px)"), m: (columns: 6, exclusive_query: "(min-width: 992px) and (max-width: 1199px)", query: "(min-width: 992px)"), l: (columns: 8, exclusive_query: "(min-width: 1200px)", query: "(min-width: 1200px)"), xl: (columns: 8, exclusive_query: "(min-width: 1681px)", query: "(min-width: 1681px)"));
$modulor_breakpoints: (xs: (columns: 4, query: "all", exclusive_query: "all and (max-width: 1023px)"), m: (columns: 6, query: "all and (min-width: 1024px)", exclusive_query: "(min-width: 1024px) and (max-width: 1679px)"), l: (columns: 8, query: "all and (min-width: 1680px)", exclusive_query: "(min-width: 1680px) and (max-width: 1919px)"), xl: (columns: 10, query: "all and (min-width: 1920px)", exclusive_query: "all and (min-width: 1920px)"));
$paths: (about: O-Nas, bistro: Bistro, children_and_families: Prostor/Prostor-Pro-Deti-A-Rodiny, cinema: Kino, clubs: Prostor/Prostor-Pro-Druziny-Stacionare-Nebo-Kluby, community: Prostor, contact: Pro-Navstevniky, education_elementary_schools: Prostor/Pro-Skoly/Zakladni, education_high_schools: Prostor/Pro-Skoly/Stredni, education_kindergartens: Prostor/Pro-Skoly/Materske, education_teachers: Prostor/Pro-Skoly/Pedagog, education: Prostor/Pro-Skoly, email_signature_generator: Email, exhibitions_archive: Vystavy/Archiv, exhibitions: Vystavy, garden: Zahrada, identity: Identita, instagram_generator: Instagram, interventions_archive: Intervence/Archiv, interventions: Intervence, kavarna: Kavarna, library: Knihovna, materialova_knihovna: Materialova-Knihovna, news_archive: Aktuality/Archiv, news: Aktuality, norma_2019: NORMA/2019, norma_2020: NORMA/2020, norma: NORMA, octopus_press_coming_soon: Octopus-Press/Coming-Soon, octopus_press: Octopus-Press, plato_bauhaus: O-Nas/Plato-Bauhaus, press: Press, program_archiv: Program/Archiv, program: Program, projects_archive: Projekty/Archiv, projects: Projekty, residence: Rezidence, root: Index, screens: Screens, rostliny: Rostliny, search: Search, senior_clubs: Prostor/Prostor-Pro-Druziny-Stacionare-Nebo-Kluby/Kluby-Senioru, shared: Sdilene, shop: Prodejna, stage: Stage, stationary: Prostor/Prostor-Pro-Druziny-Stacionare-Nebo-Kluby/Stacionare, students_and_artists: Prostor/Prostor-Pro-Studenty-A-Umelce, teenagers: Prostor/Prostor-Pro-Teenagery, your_events: Prostor/Prostor-Pro-Komunitu, zahrada_pritomnosti: Zahrada-Pritomnosti);
$modulor_paths: (login: Login, logout: Logout, admin: Admin, profile: Admin/Profile, master_pages: Admin/Master-Pages, template_pages: Admin/Template-Pages, master_page_root: Admin/Master-Pages/Root, system: System, access_denied: System/Access-Denied, activity_list: System/Activity-List, attachment_list: System/Attachment-List, error_404: System/404, new_page: System/New-Page, password_reset: System/Password-Reset, registration: System/Registration, permission_list: System/Permission-List);

@font-face {
  font-family: "Plato Basic";
  font-style: normal;
  font-weight: normal;

  src: url("https://d1ogwtdcuwdlfz.cloudfront.net/media/assets/W1siZiIsInZlbmRvci9hc3NldHMvZm9udHMvcGxhdG9fYmFzaWNfMTUwMTIwMjAub3RmIl0sWyJwIiwibm9ybWFsaXplX25hbWVzIl0sWyJwIiwiZW5jb2RlIiwiZW90Il1d/plato_basic_15012020.eot?sha=4ff773d717192af2");
  src: local('☺'),
       url("https://d1ogwtdcuwdlfz.cloudfront.net/media/assets/W1siZiIsInZlbmRvci9hc3NldHMvZm9udHMvcGxhdG9fYmFzaWNfMTUwMTIwMjAub3RmIl0sWyJwIiwibm9ybWFsaXplX25hbWVzIl0sWyJwIiwiZW5jb2RlIiwiZW90Il1d/plato_basic_15012020.eot?sha=4ff773d717192af2") format('embedded-opentype'),
       url("https://d1ogwtdcuwdlfz.cloudfront.net/media/assets/W1siZiIsInZlbmRvci9hc3NldHMvZm9udHMvcGxhdG9fYmFzaWNfMTUwMTIwMjAub3RmIl0sWyJwIiwibm9ybWFsaXplX25hbWVzIl0sWyJwIiwiZW5jb2RlIiwid29mZjIiXV0/plato_basic_15012020.woff2?sha=eeba2dd402ccba03") format('woff2'),
       url("https://d1ogwtdcuwdlfz.cloudfront.net/media/assets/W1siZiIsInZlbmRvci9hc3NldHMvZm9udHMvcGxhdG9fYmFzaWNfMTUwMTIwMjAub3RmIl0sWyJwIiwibm9ybWFsaXplX25hbWVzIl0sWyJwIiwiZW5jb2RlIiwid29mZiJdXQ/plato_basic_15012020.woff?sha=5388eb038091f399") format('woff'),
       url("https://d1ogwtdcuwdlfz.cloudfront.net/media/assets/W1siZiIsInZlbmRvci9hc3NldHMvZm9udHMvcGxhdG9fYmFzaWNfMTUwMTIwMjAub3RmIl0sWyJwIiwibm9ybWFsaXplX25hbWVzIl0sWyJwIiwiZW5jb2RlIiwidHRmIl0sWyJwIiwidHRmX2F1dG9oaW50Il1d/plato_basic_15012020.ttf?sha=fef96d52e67ea31d")  format('truetype'),
       url("https://d1ogwtdcuwdlfz.cloudfront.net/media/assets/W1siZiIsInZlbmRvci9hc3NldHMvZm9udHMvcGxhdG9fYmFzaWNfMTUwMTIwMjAub3RmIl0sWyJwIiwibm9ybWFsaXplX25hbWVzIl0sWyJwIiwiZW5jb2RlIiwic3ZnIl1d/plato_basic_15012020.svg?sha=128573b3e1cacba5") format('svg');
}

.plato_basic {
  font-family: "Plato Basic";
}

@font-face {
  font-family: "Plato Outline";
  font-style: normal;
  font-weight: normal;

  src: url("https://d1ogwtdcuwdlfz.cloudfront.net/media/assets/W1siZiIsInZlbmRvci9hc3NldHMvZm9udHMvcGxhdG9fb3V0bGluZV8xMDExMjAyMC5vdGYiXSxbInAiLCJub3JtYWxpemVfbmFtZXMiXSxbInAiLCJlbmNvZGUiLCJlb3QiXV0/plato_outline_10112020.eot?sha=51291150262464a2");
  src: local('☺'),
       url("https://d1ogwtdcuwdlfz.cloudfront.net/media/assets/W1siZiIsInZlbmRvci9hc3NldHMvZm9udHMvcGxhdG9fb3V0bGluZV8xMDExMjAyMC5vdGYiXSxbInAiLCJub3JtYWxpemVfbmFtZXMiXSxbInAiLCJlbmNvZGUiLCJlb3QiXV0/plato_outline_10112020.eot?sha=51291150262464a2") format('embedded-opentype'),
       url("https://d1ogwtdcuwdlfz.cloudfront.net/media/assets/W1siZiIsInZlbmRvci9hc3NldHMvZm9udHMvcGxhdG9fb3V0bGluZV8xMDExMjAyMC5vdGYiXSxbInAiLCJub3JtYWxpemVfbmFtZXMiXSxbInAiLCJlbmNvZGUiLCJ3b2ZmMiJdXQ/plato_outline_10112020.woff2?sha=976afba460a81578") format('woff2'),
       url("https://d1ogwtdcuwdlfz.cloudfront.net/media/assets/W1siZiIsInZlbmRvci9hc3NldHMvZm9udHMvcGxhdG9fb3V0bGluZV8xMDExMjAyMC5vdGYiXSxbInAiLCJub3JtYWxpemVfbmFtZXMiXSxbInAiLCJlbmNvZGUiLCJ3b2ZmIl1d/plato_outline_10112020.woff?sha=e449dd0e6cfb7f6a") format('woff'),
       url("https://d1ogwtdcuwdlfz.cloudfront.net/media/assets/W1siZiIsInZlbmRvci9hc3NldHMvZm9udHMvcGxhdG9fb3V0bGluZV8xMDExMjAyMC5vdGYiXSxbInAiLCJub3JtYWxpemVfbmFtZXMiXSxbInAiLCJlbmNvZGUiLCJ0dGYiXSxbInAiLCJ0dGZfYXV0b2hpbnQiXV0/plato_outline_10112020.ttf?sha=b389d3c0d81f5fca")  format('truetype'),
       url("https://d1ogwtdcuwdlfz.cloudfront.net/media/assets/W1siZiIsInZlbmRvci9hc3NldHMvZm9udHMvcGxhdG9fb3V0bGluZV8xMDExMjAyMC5vdGYiXSxbInAiLCJub3JtYWxpemVfbmFtZXMiXSxbInAiLCJlbmNvZGUiLCJzdmciXV0/plato_outline_10112020.svg?sha=491b7521550bf987") format('svg');
}

.plato_outline {
  font-family: "Plato Outline";
}

@font-face {
  font-family: "Plato Text";
  font-style: normal;
  font-weight: normal;

  src: url("https://d1ogwtdcuwdlfz.cloudfront.net/media/assets/W1siZiIsInZlbmRvci9hc3NldHMvZm9udHMvcGxhdG9fdGV4dF8xNTAxMjAyMC5vdGYiXSxbInAiLCJub3JtYWxpemVfbmFtZXMiXSxbInAiLCJlbmNvZGUiLCJlb3QiXV0/plato_text_15012020.eot?sha=5f4aae089146b01b");
  src: local('☺'),
       url("https://d1ogwtdcuwdlfz.cloudfront.net/media/assets/W1siZiIsInZlbmRvci9hc3NldHMvZm9udHMvcGxhdG9fdGV4dF8xNTAxMjAyMC5vdGYiXSxbInAiLCJub3JtYWxpemVfbmFtZXMiXSxbInAiLCJlbmNvZGUiLCJlb3QiXV0/plato_text_15012020.eot?sha=5f4aae089146b01b") format('embedded-opentype'),
       url("https://d1ogwtdcuwdlfz.cloudfront.net/media/assets/W1siZiIsInZlbmRvci9hc3NldHMvZm9udHMvcGxhdG9fdGV4dF8xNTAxMjAyMC5vdGYiXSxbInAiLCJub3JtYWxpemVfbmFtZXMiXSxbInAiLCJlbmNvZGUiLCJ3b2ZmMiJdXQ/plato_text_15012020.woff2?sha=cd76d75dea1ad9a6") format('woff2'),
       url("https://d1ogwtdcuwdlfz.cloudfront.net/media/assets/W1siZiIsInZlbmRvci9hc3NldHMvZm9udHMvcGxhdG9fdGV4dF8xNTAxMjAyMC5vdGYiXSxbInAiLCJub3JtYWxpemVfbmFtZXMiXSxbInAiLCJlbmNvZGUiLCJ3b2ZmIl1d/plato_text_15012020.woff?sha=9f24d1141d247a9b") format('woff'),
       url("https://d1ogwtdcuwdlfz.cloudfront.net/media/assets/W1siZiIsInZlbmRvci9hc3NldHMvZm9udHMvcGxhdG9fdGV4dF8xNTAxMjAyMC5vdGYiXSxbInAiLCJub3JtYWxpemVfbmFtZXMiXSxbInAiLCJlbmNvZGUiLCJ0dGYiXSxbInAiLCJ0dGZfYXV0b2hpbnQiXV0/plato_text_15012020.ttf?sha=e945e5ea84313f9f")  format('truetype'),
       url("https://d1ogwtdcuwdlfz.cloudfront.net/media/assets/W1siZiIsInZlbmRvci9hc3NldHMvZm9udHMvcGxhdG9fdGV4dF8xNTAxMjAyMC5vdGYiXSxbInAiLCJub3JtYWxpemVfbmFtZXMiXSxbInAiLCJlbmNvZGUiLCJzdmciXV0/plato_text_15012020.svg?sha=e478ced930719b11") format('svg');
}

.plato_text {
  font-family: "Plato Text";
}

@font-face {
  font-family: "Plato Regular";
  font-style: normal;
  font-weight: normal;

  src: url("https://d1ogwtdcuwdlfz.cloudfront.net/media/assets/W1siZiIsInZlbmRvci9hc3NldHMvZm9udHMvMjAyNC0xMS0yNi1QbGF0b1NhbnMtUmVndWxhci5vdGYiXSxbInAiLCJub3JtYWxpemVfbmFtZXMiXSxbInAiLCJlbmNvZGUiLCJlb3QiXV0/2024-11-26-PlatoSans-Regular.eot?sha=bd7341cde77b07a6");
  src: local('☺'),
       url("https://d1ogwtdcuwdlfz.cloudfront.net/media/assets/W1siZiIsInZlbmRvci9hc3NldHMvZm9udHMvMjAyNC0xMS0yNi1QbGF0b1NhbnMtUmVndWxhci5vdGYiXSxbInAiLCJub3JtYWxpemVfbmFtZXMiXSxbInAiLCJlbmNvZGUiLCJlb3QiXV0/2024-11-26-PlatoSans-Regular.eot?sha=bd7341cde77b07a6") format('embedded-opentype'),
       url("https://d1ogwtdcuwdlfz.cloudfront.net/media/assets/W1siZiIsInZlbmRvci9hc3NldHMvZm9udHMvMjAyNC0xMS0yNi1QbGF0b1NhbnMtUmVndWxhci5vdGYiXSxbInAiLCJub3JtYWxpemVfbmFtZXMiXSxbInAiLCJlbmNvZGUiLCJ3b2ZmMiJdXQ/2024-11-26-PlatoSans-Regular.woff2?sha=155e6228472ac444") format('woff2'),
       url("https://d1ogwtdcuwdlfz.cloudfront.net/media/assets/W1siZiIsInZlbmRvci9hc3NldHMvZm9udHMvMjAyNC0xMS0yNi1QbGF0b1NhbnMtUmVndWxhci5vdGYiXSxbInAiLCJub3JtYWxpemVfbmFtZXMiXSxbInAiLCJlbmNvZGUiLCJ3b2ZmIl1d/2024-11-26-PlatoSans-Regular.woff?sha=729fc1e2dc8a55b3") format('woff'),
       url("https://d1ogwtdcuwdlfz.cloudfront.net/media/assets/W1siZiIsInZlbmRvci9hc3NldHMvZm9udHMvMjAyNC0xMS0yNi1QbGF0b1NhbnMtUmVndWxhci5vdGYiXSxbInAiLCJub3JtYWxpemVfbmFtZXMiXSxbInAiLCJlbmNvZGUiLCJ0dGYiXSxbInAiLCJ0dGZfYXV0b2hpbnQiXV0/2024-11-26-PlatoSans-Regular.ttf?sha=a2de65b6d03e8d3f")  format('truetype'),
       url("https://d1ogwtdcuwdlfz.cloudfront.net/media/assets/W1siZiIsInZlbmRvci9hc3NldHMvZm9udHMvMjAyNC0xMS0yNi1QbGF0b1NhbnMtUmVndWxhci5vdGYiXSxbInAiLCJub3JtYWxpemVfbmFtZXMiXSxbInAiLCJlbmNvZGUiLCJzdmciXV0/2024-11-26-PlatoSans-Regular.svg?sha=725ce1c580e2ed6d") format('svg');
}

.plato_regular {
  font-family: "Plato Regular";
}

@font-face {
  font-family: "Plato Regular";
  font-style: italic;
  font-weight: normal;

  src: url("https://d1ogwtdcuwdlfz.cloudfront.net/media/assets/W1siZiIsInZlbmRvci9hc3NldHMvZm9udHMvMjAyNC0xMS0yNi1QbGF0b1NhbnMtUmVndWxhckl0YWxpYy5vdGYiXSxbInAiLCJub3JtYWxpemVfbmFtZXMiXSxbInAiLCJlbmNvZGUiLCJlb3QiXV0/2024-11-26-PlatoSans-RegularItalic.eot?sha=323907036163bc21");
  src: local('☺'),
       url("https://d1ogwtdcuwdlfz.cloudfront.net/media/assets/W1siZiIsInZlbmRvci9hc3NldHMvZm9udHMvMjAyNC0xMS0yNi1QbGF0b1NhbnMtUmVndWxhckl0YWxpYy5vdGYiXSxbInAiLCJub3JtYWxpemVfbmFtZXMiXSxbInAiLCJlbmNvZGUiLCJlb3QiXV0/2024-11-26-PlatoSans-RegularItalic.eot?sha=323907036163bc21") format('embedded-opentype'),
       url("https://d1ogwtdcuwdlfz.cloudfront.net/media/assets/W1siZiIsInZlbmRvci9hc3NldHMvZm9udHMvMjAyNC0xMS0yNi1QbGF0b1NhbnMtUmVndWxhckl0YWxpYy5vdGYiXSxbInAiLCJub3JtYWxpemVfbmFtZXMiXSxbInAiLCJlbmNvZGUiLCJ3b2ZmMiJdXQ/2024-11-26-PlatoSans-RegularItalic.woff2?sha=ddf3f3e591d7faaf") format('woff2'),
       url("https://d1ogwtdcuwdlfz.cloudfront.net/media/assets/W1siZiIsInZlbmRvci9hc3NldHMvZm9udHMvMjAyNC0xMS0yNi1QbGF0b1NhbnMtUmVndWxhckl0YWxpYy5vdGYiXSxbInAiLCJub3JtYWxpemVfbmFtZXMiXSxbInAiLCJlbmNvZGUiLCJ3b2ZmIl1d/2024-11-26-PlatoSans-RegularItalic.woff?sha=d177d61a7ac0d4d8") format('woff'),
       url("https://d1ogwtdcuwdlfz.cloudfront.net/media/assets/W1siZiIsInZlbmRvci9hc3NldHMvZm9udHMvMjAyNC0xMS0yNi1QbGF0b1NhbnMtUmVndWxhckl0YWxpYy5vdGYiXSxbInAiLCJub3JtYWxpemVfbmFtZXMiXSxbInAiLCJlbmNvZGUiLCJ0dGYiXSxbInAiLCJ0dGZfYXV0b2hpbnQiXV0/2024-11-26-PlatoSans-RegularItalic.ttf?sha=c61ab53277d39d83")  format('truetype'),
       url("https://d1ogwtdcuwdlfz.cloudfront.net/media/assets/W1siZiIsInZlbmRvci9hc3NldHMvZm9udHMvMjAyNC0xMS0yNi1QbGF0b1NhbnMtUmVndWxhckl0YWxpYy5vdGYiXSxbInAiLCJub3JtYWxpemVfbmFtZXMiXSxbInAiLCJlbmNvZGUiLCJzdmciXV0/2024-11-26-PlatoSans-RegularItalic.svg?sha=7a0db75c7844f358") format('svg');
}

.plato_regular_italic {
  font-family: "Plato Regular";
}

@font-face {
  font-family: "Plato Medium";
  font-style: normal;
  font-weight: normal;

  src: url("https://d1ogwtdcuwdlfz.cloudfront.net/media/assets/W1siZiIsInZlbmRvci9hc3NldHMvZm9udHMvMjAyNC0xMS0yNi1QbGF0b1NhbnMtTWVkaXVtLm90ZiJdLFsicCIsIm5vcm1hbGl6ZV9uYW1lcyJdLFsicCIsImVuY29kZSIsImVvdCJdXQ/2024-11-26-PlatoSans-Medium.eot?sha=797fba7cda294720");
  src: local('☺'),
       url("https://d1ogwtdcuwdlfz.cloudfront.net/media/assets/W1siZiIsInZlbmRvci9hc3NldHMvZm9udHMvMjAyNC0xMS0yNi1QbGF0b1NhbnMtTWVkaXVtLm90ZiJdLFsicCIsIm5vcm1hbGl6ZV9uYW1lcyJdLFsicCIsImVuY29kZSIsImVvdCJdXQ/2024-11-26-PlatoSans-Medium.eot?sha=797fba7cda294720") format('embedded-opentype'),
       url("https://d1ogwtdcuwdlfz.cloudfront.net/media/assets/W1siZiIsInZlbmRvci9hc3NldHMvZm9udHMvMjAyNC0xMS0yNi1QbGF0b1NhbnMtTWVkaXVtLm90ZiJdLFsicCIsIm5vcm1hbGl6ZV9uYW1lcyJdLFsicCIsImVuY29kZSIsIndvZmYyIl1d/2024-11-26-PlatoSans-Medium.woff2?sha=ef244c9b1e6bba05") format('woff2'),
       url("https://d1ogwtdcuwdlfz.cloudfront.net/media/assets/W1siZiIsInZlbmRvci9hc3NldHMvZm9udHMvMjAyNC0xMS0yNi1QbGF0b1NhbnMtTWVkaXVtLm90ZiJdLFsicCIsIm5vcm1hbGl6ZV9uYW1lcyJdLFsicCIsImVuY29kZSIsIndvZmYiXV0/2024-11-26-PlatoSans-Medium.woff?sha=63e7f538d713ed42") format('woff'),
       url("https://d1ogwtdcuwdlfz.cloudfront.net/media/assets/W1siZiIsInZlbmRvci9hc3NldHMvZm9udHMvMjAyNC0xMS0yNi1QbGF0b1NhbnMtTWVkaXVtLm90ZiJdLFsicCIsIm5vcm1hbGl6ZV9uYW1lcyJdLFsicCIsImVuY29kZSIsInR0ZiJdLFsicCIsInR0Zl9hdXRvaGludCJdXQ/2024-11-26-PlatoSans-Medium.ttf?sha=bdc35a46f6e39b40")  format('truetype'),
       url("https://d1ogwtdcuwdlfz.cloudfront.net/media/assets/W1siZiIsInZlbmRvci9hc3NldHMvZm9udHMvMjAyNC0xMS0yNi1QbGF0b1NhbnMtTWVkaXVtLm90ZiJdLFsicCIsIm5vcm1hbGl6ZV9uYW1lcyJdLFsicCIsImVuY29kZSIsInN2ZyJdXQ/2024-11-26-PlatoSans-Medium.svg?sha=9afd2c043f54d2f1") format('svg');
}

.plato_medium {
  font-family: "Plato Medium";
}


// for compatibility with past newsletters

@font-face {
  font-family: "Plato Basic";
  font-style: normal;
  font-weight: normal;

  src: url("https://d1ogwtdcuwdlfz.cloudfront.net/media/assets/W1siZiIsInZlbmRvci9hc3NldHMvZm9udHMvcGxhdG9fYmFzaWNfMDIwMTIwMjAub3RmIl0sWyJwIiwibm9ybWFsaXplX25hbWVzIl0sWyJwIiwiZW5jb2RlIiwiZW90Il1d/plato_basic_02012020.eot?sha=7939bf0b1d3447cb");
  src: local('☺'),
       url("https://d1ogwtdcuwdlfz.cloudfront.net/media/assets/W1siZiIsInZlbmRvci9hc3NldHMvZm9udHMvcGxhdG9fYmFzaWNfMDIwMTIwMjAub3RmIl0sWyJwIiwibm9ybWFsaXplX25hbWVzIl0sWyJwIiwiZW5jb2RlIiwiZW90Il1d/plato_basic_02012020.eot?sha=7939bf0b1d3447cb") format('embedded-opentype'),
       url("https://d1ogwtdcuwdlfz.cloudfront.net/media/assets/W1siZiIsInZlbmRvci9hc3NldHMvZm9udHMvcGxhdG9fYmFzaWNfMDIwMTIwMjAub3RmIl0sWyJwIiwibm9ybWFsaXplX25hbWVzIl0sWyJwIiwiZW5jb2RlIiwid29mZjIiXV0/plato_basic_02012020.woff2?sha=d3b94ce4a835dfac") format('woff2'),
       url("https://d1ogwtdcuwdlfz.cloudfront.net/media/assets/W1siZiIsInZlbmRvci9hc3NldHMvZm9udHMvcGxhdG9fYmFzaWNfMDIwMTIwMjAub3RmIl0sWyJwIiwibm9ybWFsaXplX25hbWVzIl0sWyJwIiwiZW5jb2RlIiwid29mZiJdXQ/plato_basic_02012020.woff?sha=c539c095639d80de") format('woff'),
       url("https://d1ogwtdcuwdlfz.cloudfront.net/media/assets/W1siZiIsInZlbmRvci9hc3NldHMvZm9udHMvcGxhdG9fYmFzaWNfMDIwMTIwMjAub3RmIl0sWyJwIiwibm9ybWFsaXplX25hbWVzIl0sWyJwIiwiZW5jb2RlIiwidHRmIl0sWyJwIiwidHRmX2F1dG9oaW50Il1d/plato_basic_02012020.ttf?sha=59d28b49920a9b4a")  format('truetype'),
       url("https://d1ogwtdcuwdlfz.cloudfront.net/media/assets/W1siZiIsInZlbmRvci9hc3NldHMvZm9udHMvcGxhdG9fYmFzaWNfMDIwMTIwMjAub3RmIl0sWyJwIiwibm9ybWFsaXplX25hbWVzIl0sWyJwIiwiZW5jb2RlIiwic3ZnIl1d/plato_basic_02012020.svg?sha=53bbb4f19ec2669e") format('svg');
}

@font-face {
  font-family: "Plato Outline";
  font-style: normal;
  font-weight: normal;

  src: url("https://d1ogwtdcuwdlfz.cloudfront.net/media/assets/W1siZiIsInZlbmRvci9hc3NldHMvZm9udHMvcGxhdG9fb3V0bGluZV8wMjAxMjAyMC5vdGYiXSxbInAiLCJub3JtYWxpemVfbmFtZXMiXSxbInAiLCJlbmNvZGUiLCJlb3QiXV0/plato_outline_02012020.eot?sha=cae963fc2bc75244");
  src: local('☺'),
       url("https://d1ogwtdcuwdlfz.cloudfront.net/media/assets/W1siZiIsInZlbmRvci9hc3NldHMvZm9udHMvcGxhdG9fb3V0bGluZV8wMjAxMjAyMC5vdGYiXSxbInAiLCJub3JtYWxpemVfbmFtZXMiXSxbInAiLCJlbmNvZGUiLCJlb3QiXV0/plato_outline_02012020.eot?sha=cae963fc2bc75244") format('embedded-opentype'),
       url("https://d1ogwtdcuwdlfz.cloudfront.net/media/assets/W1siZiIsInZlbmRvci9hc3NldHMvZm9udHMvcGxhdG9fb3V0bGluZV8wMjAxMjAyMC5vdGYiXSxbInAiLCJub3JtYWxpemVfbmFtZXMiXSxbInAiLCJlbmNvZGUiLCJ3b2ZmMiJdXQ/plato_outline_02012020.woff2?sha=41b995fce782ed9f") format('woff2'),
       url("https://d1ogwtdcuwdlfz.cloudfront.net/media/assets/W1siZiIsInZlbmRvci9hc3NldHMvZm9udHMvcGxhdG9fb3V0bGluZV8wMjAxMjAyMC5vdGYiXSxbInAiLCJub3JtYWxpemVfbmFtZXMiXSxbInAiLCJlbmNvZGUiLCJ3b2ZmIl1d/plato_outline_02012020.woff?sha=8a2a8da85b79b108") format('woff'),
       url("https://d1ogwtdcuwdlfz.cloudfront.net/media/assets/W1siZiIsInZlbmRvci9hc3NldHMvZm9udHMvcGxhdG9fb3V0bGluZV8wMjAxMjAyMC5vdGYiXSxbInAiLCJub3JtYWxpemVfbmFtZXMiXSxbInAiLCJlbmNvZGUiLCJ0dGYiXSxbInAiLCJ0dGZfYXV0b2hpbnQiXV0/plato_outline_02012020.ttf?sha=4fcbb9b33f71a467")  format('truetype'),
       url("https://d1ogwtdcuwdlfz.cloudfront.net/media/assets/W1siZiIsInZlbmRvci9hc3NldHMvZm9udHMvcGxhdG9fb3V0bGluZV8wMjAxMjAyMC5vdGYiXSxbInAiLCJub3JtYWxpemVfbmFtZXMiXSxbInAiLCJlbmNvZGUiLCJzdmciXV0/plato_outline_02012020.svg?sha=35ab03568e620531") format('svg');
}

@font-face {
  font-family: "Plato Text";
  font-style: normal;
  font-weight: normal;

  src: url("https://d1ogwtdcuwdlfz.cloudfront.net/media/assets/W1siZiIsInZlbmRvci9hc3NldHMvZm9udHMvcGxhdG9fdGV4dF8wMjAxMjAyMC5vdGYiXSxbInAiLCJub3JtYWxpemVfbmFtZXMiXSxbInAiLCJlbmNvZGUiLCJlb3QiXV0/plato_text_02012020.eot?sha=f4205aa923a1518e");
  src: local('☺'),
       url("https://d1ogwtdcuwdlfz.cloudfront.net/media/assets/W1siZiIsInZlbmRvci9hc3NldHMvZm9udHMvcGxhdG9fdGV4dF8wMjAxMjAyMC5vdGYiXSxbInAiLCJub3JtYWxpemVfbmFtZXMiXSxbInAiLCJlbmNvZGUiLCJlb3QiXV0/plato_text_02012020.eot?sha=f4205aa923a1518e") format('embedded-opentype'),
       url("https://d1ogwtdcuwdlfz.cloudfront.net/media/assets/W1siZiIsInZlbmRvci9hc3NldHMvZm9udHMvcGxhdG9fdGV4dF8wMjAxMjAyMC5vdGYiXSxbInAiLCJub3JtYWxpemVfbmFtZXMiXSxbInAiLCJlbmNvZGUiLCJ3b2ZmMiJdXQ/plato_text_02012020.woff2?sha=124716db416a88d1") format('woff2'),
       url("https://d1ogwtdcuwdlfz.cloudfront.net/media/assets/W1siZiIsInZlbmRvci9hc3NldHMvZm9udHMvcGxhdG9fdGV4dF8wMjAxMjAyMC5vdGYiXSxbInAiLCJub3JtYWxpemVfbmFtZXMiXSxbInAiLCJlbmNvZGUiLCJ3b2ZmIl1d/plato_text_02012020.woff?sha=f720e3582a5b83c1") format('woff'),
       url("https://d1ogwtdcuwdlfz.cloudfront.net/media/assets/W1siZiIsInZlbmRvci9hc3NldHMvZm9udHMvcGxhdG9fdGV4dF8wMjAxMjAyMC5vdGYiXSxbInAiLCJub3JtYWxpemVfbmFtZXMiXSxbInAiLCJlbmNvZGUiLCJ0dGYiXSxbInAiLCJ0dGZfYXV0b2hpbnQiXV0/plato_text_02012020.ttf?sha=8df3b3b884e29432")  format('truetype'),
       url("https://d1ogwtdcuwdlfz.cloudfront.net/media/assets/W1siZiIsInZlbmRvci9hc3NldHMvZm9udHMvcGxhdG9fdGV4dF8wMjAxMjAyMC5vdGYiXSxbInAiLCJub3JtYWxpemVfbmFtZXMiXSxbInAiLCJlbmNvZGUiLCJzdmciXV0/plato_text_02012020.svg?sha=58e50a9372fe1ff7") format('svg');
}


// for compatibility with past newsletters

@font-face {
  font-family: "Plato Basic Compat";
  font-style: normal;
  font-weight: normal;

  src: url("https://d1ogwtdcuwdlfz.cloudfront.net/media/assets/W1siZiIsInZlbmRvci9hc3NldHMvZm9udHMvcGxhdG9fYmFzaWNfMjUwNDIwMTkub3RmIl0sWyJwIiwibm9ybWFsaXplX25hbWVzIl0sWyJwIiwiZW5jb2RlIiwiZW90Il1d/plato_basic_25042019.eot?sha=ccab7179001c224a");
  src: local('☺'),
       url("https://d1ogwtdcuwdlfz.cloudfront.net/media/assets/W1siZiIsInZlbmRvci9hc3NldHMvZm9udHMvcGxhdG9fYmFzaWNfMjUwNDIwMTkub3RmIl0sWyJwIiwibm9ybWFsaXplX25hbWVzIl0sWyJwIiwiZW5jb2RlIiwiZW90Il1d/plato_basic_25042019.eot?sha=ccab7179001c224a") format('embedded-opentype'),
       url("https://d1ogwtdcuwdlfz.cloudfront.net/media/assets/W1siZiIsInZlbmRvci9hc3NldHMvZm9udHMvcGxhdG9fYmFzaWNfMjUwNDIwMTkub3RmIl0sWyJwIiwibm9ybWFsaXplX25hbWVzIl0sWyJwIiwiZW5jb2RlIiwid29mZjIiXV0/plato_basic_25042019.woff2?sha=1e169b0fb2a7a62b") format('woff2'),
       url("https://d1ogwtdcuwdlfz.cloudfront.net/media/assets/W1siZiIsInZlbmRvci9hc3NldHMvZm9udHMvcGxhdG9fYmFzaWNfMjUwNDIwMTkub3RmIl0sWyJwIiwibm9ybWFsaXplX25hbWVzIl0sWyJwIiwiZW5jb2RlIiwid29mZiJdXQ/plato_basic_25042019.woff?sha=a0df40723db3204c") format('woff'),
       url("https://d1ogwtdcuwdlfz.cloudfront.net/media/assets/W1siZiIsInZlbmRvci9hc3NldHMvZm9udHMvcGxhdG9fYmFzaWNfMjUwNDIwMTkub3RmIl0sWyJwIiwibm9ybWFsaXplX25hbWVzIl0sWyJwIiwiZW5jb2RlIiwidHRmIl0sWyJwIiwidHRmX2F1dG9oaW50Il1d/plato_basic_25042019.ttf?sha=c8449cae110203e0")  format('truetype'),
       url("https://d1ogwtdcuwdlfz.cloudfront.net/media/assets/W1siZiIsInZlbmRvci9hc3NldHMvZm9udHMvcGxhdG9fYmFzaWNfMjUwNDIwMTkub3RmIl0sWyJwIiwibm9ybWFsaXplX25hbWVzIl0sWyJwIiwiZW5jb2RlIiwic3ZnIl1d/plato_basic_25042019.svg?sha=a0fe89de7ef9cd9f") format('svg');
}

@font-face {
  font-family: "Plato Outline Compat";
  font-style: normal;
  font-weight: normal;

  src: url("https://d1ogwtdcuwdlfz.cloudfront.net/media/assets/W1siZiIsInZlbmRvci9hc3NldHMvZm9udHMvcGxhdG9fb3V0bGluZV8yNTA0MjAxOS5vdGYiXSxbInAiLCJub3JtYWxpemVfbmFtZXMiXSxbInAiLCJlbmNvZGUiLCJlb3QiXV0/plato_outline_25042019.eot?sha=167a36ef9bb3bc60");
  src: local('☺'),
       url("https://d1ogwtdcuwdlfz.cloudfront.net/media/assets/W1siZiIsInZlbmRvci9hc3NldHMvZm9udHMvcGxhdG9fb3V0bGluZV8yNTA0MjAxOS5vdGYiXSxbInAiLCJub3JtYWxpemVfbmFtZXMiXSxbInAiLCJlbmNvZGUiLCJlb3QiXV0/plato_outline_25042019.eot?sha=167a36ef9bb3bc60") format('embedded-opentype'),
       url("https://d1ogwtdcuwdlfz.cloudfront.net/media/assets/W1siZiIsInZlbmRvci9hc3NldHMvZm9udHMvcGxhdG9fb3V0bGluZV8yNTA0MjAxOS5vdGYiXSxbInAiLCJub3JtYWxpemVfbmFtZXMiXSxbInAiLCJlbmNvZGUiLCJ3b2ZmMiJdXQ/plato_outline_25042019.woff2?sha=a05e0710faa4c267") format('woff2'),
       url("https://d1ogwtdcuwdlfz.cloudfront.net/media/assets/W1siZiIsInZlbmRvci9hc3NldHMvZm9udHMvcGxhdG9fb3V0bGluZV8yNTA0MjAxOS5vdGYiXSxbInAiLCJub3JtYWxpemVfbmFtZXMiXSxbInAiLCJlbmNvZGUiLCJ3b2ZmIl1d/plato_outline_25042019.woff?sha=837d7c9c213853e9") format('woff'),
       url("https://d1ogwtdcuwdlfz.cloudfront.net/media/assets/W1siZiIsInZlbmRvci9hc3NldHMvZm9udHMvcGxhdG9fb3V0bGluZV8yNTA0MjAxOS5vdGYiXSxbInAiLCJub3JtYWxpemVfbmFtZXMiXSxbInAiLCJlbmNvZGUiLCJ0dGYiXSxbInAiLCJ0dGZfYXV0b2hpbnQiXV0/plato_outline_25042019.ttf?sha=1dfcde22e2fec97f")  format('truetype'),
       url("https://d1ogwtdcuwdlfz.cloudfront.net/media/assets/W1siZiIsInZlbmRvci9hc3NldHMvZm9udHMvcGxhdG9fb3V0bGluZV8yNTA0MjAxOS5vdGYiXSxbInAiLCJub3JtYWxpemVfbmFtZXMiXSxbInAiLCJlbmNvZGUiLCJzdmciXV0/plato_outline_25042019.svg?sha=6e00bdfbcc8ce690") format('svg');
}

@font-face {
  font-family: "Plato Text Compat";
  font-style: normal;
  font-weight: normal;

  src: url("https://d1ogwtdcuwdlfz.cloudfront.net/media/assets/W1siZiIsInZlbmRvci9hc3NldHMvZm9udHMvcGxhdG9fdGV4dF8yNTA0MjAxOS5vdGYiXSxbInAiLCJub3JtYWxpemVfbmFtZXMiXSxbInAiLCJlbmNvZGUiLCJlb3QiXV0/plato_text_25042019.eot?sha=a98b3a79687f08e9");
  src: local('☺'),
       url("https://d1ogwtdcuwdlfz.cloudfront.net/media/assets/W1siZiIsInZlbmRvci9hc3NldHMvZm9udHMvcGxhdG9fdGV4dF8yNTA0MjAxOS5vdGYiXSxbInAiLCJub3JtYWxpemVfbmFtZXMiXSxbInAiLCJlbmNvZGUiLCJlb3QiXV0/plato_text_25042019.eot?sha=a98b3a79687f08e9") format('embedded-opentype'),
       url("https://d1ogwtdcuwdlfz.cloudfront.net/media/assets/W1siZiIsInZlbmRvci9hc3NldHMvZm9udHMvcGxhdG9fdGV4dF8yNTA0MjAxOS5vdGYiXSxbInAiLCJub3JtYWxpemVfbmFtZXMiXSxbInAiLCJlbmNvZGUiLCJ3b2ZmMiJdXQ/plato_text_25042019.woff2?sha=a1e3ff56fb7ede74") format('woff2'),
       url("https://d1ogwtdcuwdlfz.cloudfront.net/media/assets/W1siZiIsInZlbmRvci9hc3NldHMvZm9udHMvcGxhdG9fdGV4dF8yNTA0MjAxOS5vdGYiXSxbInAiLCJub3JtYWxpemVfbmFtZXMiXSxbInAiLCJlbmNvZGUiLCJ3b2ZmIl1d/plato_text_25042019.woff?sha=44a1549ed0af8982") format('woff'),
       url("https://d1ogwtdcuwdlfz.cloudfront.net/media/assets/W1siZiIsInZlbmRvci9hc3NldHMvZm9udHMvcGxhdG9fdGV4dF8yNTA0MjAxOS5vdGYiXSxbInAiLCJub3JtYWxpemVfbmFtZXMiXSxbInAiLCJlbmNvZGUiLCJ0dGYiXSxbInAiLCJ0dGZfYXV0b2hpbnQiXV0/plato_text_25042019.ttf?sha=e08a5e4f019fe2ab")  format('truetype'),
       url("https://d1ogwtdcuwdlfz.cloudfront.net/media/assets/W1siZiIsInZlbmRvci9hc3NldHMvZm9udHMvcGxhdG9fdGV4dF8yNTA0MjAxOS5vdGYiXSxbInAiLCJub3JtYWxpemVfbmFtZXMiXSxbInAiLCJlbmNvZGUiLCJzdmciXV0/plato_text_25042019.svg?sha=2a6186e1f9b685f1") format('svg');
}

