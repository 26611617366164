@charset "UTF-8";
.event_list_header_module__current_tag {
  font-family: 'Plato Regular', sans-serif;
  font-weight: normal;
  letter-spacing: -0.02em;
  color: black;
  cursor: pointer;
  margin: 0;
  padding: 0; }
  @media all and (max-width: 767px) {
    .event_list_header_module__current_tag {
      font-size: 2.88rem;
      line-height: 3.12rem; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .event_list_header_module__current_tag {
      font-size: 5.76rem;
      line-height: 6.24rem; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .event_list_header_module__current_tag {
      font-size: 7.68rem;
      line-height: 8.32rem; } }
  @media (min-width: 1200px) {
    .event_list_header_module__current_tag {
      font-size: 9.6rem;
      line-height: 10.4rem; } }
  @media (min-width: 1681px) {
    .event_list_header_module__current_tag {
      font-size: 9.6rem;
      line-height: 10.4rem; } }
  .event_list_header_module__current_tag:after {
    content: " …"; }
  .event_list_header_module__current_tag:hover {
    font-family: 'Plato Medium', sans-serif;
    font-weight: normal; }
