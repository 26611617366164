.exhibition_header_module__exhibitions_page {
  font-family: 'Plato Outline', sans-serif;
  font-weight: normal;
  letter-spacing: -0.02em;
  color: black;
  text-decoration: none; }
  @media all and (max-width: 767px) {
    .exhibition_header_module__exhibitions_page {
      font-size: 2.88rem;
      line-height: 3.12rem; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .exhibition_header_module__exhibitions_page {
      font-size: 5.76rem;
      line-height: 6.24rem; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .exhibition_header_module__exhibitions_page {
      font-size: 7.68rem;
      line-height: 8.32rem; } }
  @media (min-width: 1200px) {
    .exhibition_header_module__exhibitions_page {
      font-size: 9.6rem;
      line-height: 10.4rem; } }
  @media (min-width: 1681px) {
    .exhibition_header_module__exhibitions_page {
      font-size: 9.6rem;
      line-height: 10.4rem; } }
  .exhibition_header_module__exhibitions_page:hover {
    font-family: 'Plato Basic', sans-serif;
    font-weight: normal; }

[data-identity="1.5"] .exhibition_header_module__exhibitions_page {
  font-family: 'Plato Regular', sans-serif;
  font-weight: normal;
  letter-spacing: 0.04em;
  text-transform: lowercase; }
  @media all and (max-width: 767px) {
    [data-identity="1.5"] .exhibition_header_module__exhibitions_page {
      font-size: 1.4rem;
      line-height: 1.96rem; } }
  @media (min-width: 768px) and (max-width: 991px) {
    [data-identity="1.5"] .exhibition_header_module__exhibitions_page {
      font-size: 1.6rem;
      line-height: 2.24rem; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    [data-identity="1.5"] .exhibition_header_module__exhibitions_page {
      font-size: 1.8rem;
      line-height: 2.52rem; } }
  @media (min-width: 1200px) {
    [data-identity="1.5"] .exhibition_header_module__exhibitions_page {
      font-size: 2rem;
      line-height: 2.8rem; } }
  @media (min-width: 1681px) {
    [data-identity="1.5"] .exhibition_header_module__exhibitions_page {
      font-size: 2rem;
      line-height: 2.8rem; } }
  [data-identity="1.5"] .exhibition_header_module__exhibitions_page:hover {
    font-family: 'Plato Medium', sans-serif;
    font-weight: normal; }
