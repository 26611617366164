.project_header_module__link_to__projects_page {
  font-family: 'Plato Outline', sans-serif;
  font-weight: normal;
  letter-spacing: -0.02em; }
  @media all and (max-width: 767px) {
    .project_header_module__link_to__projects_page {
      font-size: 2.88rem;
      line-height: 3.12rem; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .project_header_module__link_to__projects_page {
      font-size: 5.76rem;
      line-height: 6.24rem; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .project_header_module__link_to__projects_page {
      font-size: 7.68rem;
      line-height: 8.32rem; } }
  @media (min-width: 1200px) {
    .project_header_module__link_to__projects_page {
      font-size: 9.6rem;
      line-height: 10.4rem; } }
  @media (min-width: 1681px) {
    .project_header_module__link_to__projects_page {
      font-size: 9.6rem;
      line-height: 10.4rem; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .project_header_module__link_to__projects_page {
      /* s */
      margin-left: 16.66667%; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .project_header_module__link_to__projects_page {
      /* m */
      margin-left: 16.66667%; } }
  @media (min-width: 1200px) {
    .project_header_module__link_to__projects_page {
      /* l */
      margin-left: 12.5%; } }
  @media (min-width: 1681px) {
    .project_header_module__link_to__projects_page {
      /* xl */
      margin-left: 12.5%; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .project_header_module__link_to__projects_page {
      /* s */
      margin-right: 16.66667%; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .project_header_module__link_to__projects_page {
      /* m */
      margin-right: 16.66667%; } }
  @media (min-width: 1200px) {
    .project_header_module__link_to__projects_page {
      /* l */
      margin-right: 12.5%; } }
  @media (min-width: 1681px) {
    .project_header_module__link_to__projects_page {
      /* xl */
      margin-right: 12.5%; } }
  @media all and (max-width: 767px) {
    .project_header_module__link_to__projects_page {
      padding-left: 1.6rem;
      padding-right: 1.6rem; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .project_header_module__link_to__projects_page {
      padding-left: 2.24rem;
      padding-right: 2.24rem; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .project_header_module__link_to__projects_page {
      padding-left: 2.52rem;
      padding-right: 2.52rem; } }
  @media (min-width: 1200px) {
    .project_header_module__link_to__projects_page {
      padding-left: 2.8rem;
      padding-right: 2.8rem; } }
  @media (min-width: 1681px) {
    .project_header_module__link_to__projects_page {
      padding-left: 2.8rem;
      padding-right: 2.8rem; } }
  .project_header_module__link_to__projects_page a {
    color: black;
    text-decoration: none; }
    .project_header_module__link_to__projects_page a:hover {
      font-family: 'Plato Basic', sans-serif;
      font-weight: normal; }

[data-identity="1.5"] .project_header_module__link_to__projects_page {
  font-family: 'Plato Regular', sans-serif;
  font-weight: normal; }
  [data-identity="1.5"] .project_header_module__link_to__projects_page a:hover {
    font-family: 'Plato Regular', sans-serif;
    font-weight: normal;
    font-style: italic; }
