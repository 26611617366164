.waqi_module__aqi {
  margin: 0;
  padding: 0;
  font-family: 'Plato Basic', sans-serif;
  font-weight: normal;
  letter-spacing: 0.04em; }
  .waqi_module__aqi:not(:last-child) {
    margin-bottom: 1em; }
  @media all and (max-width: 767px) {
    .waqi_module__aqi {
      font-size: 1.4rem;
      line-height: 1.96rem; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .waqi_module__aqi {
      font-size: 1.6rem;
      line-height: 2.24rem; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .waqi_module__aqi {
      font-size: 1.8rem;
      line-height: 2.52rem; } }
  @media (min-width: 1200px) {
    .waqi_module__aqi {
      font-size: 2rem;
      line-height: 2.8rem; } }
  @media (min-width: 1681px) {
    .waqi_module__aqi {
      font-size: 2rem;
      line-height: 2.8rem; } }
  [data-aqi-level="1"] .waqi_module__aqi,
  [data-aqi-level="2"] .waqi_module__aqi {
    color: lime; }
  [data-aqi-level="3"] .waqi_module__aqi,
  [data-aqi-level="4"] .waqi_module__aqi {
    color: #ff6400; }
  [data-aqi-level="5"] .waqi_module__aqi,
  [data-aqi-level="6"] .waqi_module__aqi,
  [data-aqi-level="7"] .waqi_module__aqi {
    color: #fa0000; }
