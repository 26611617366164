.event_header_module__title {
  padding: 0;
  margin: 0;
  font-family: 'Plato Basic', sans-serif;
  font-weight: normal;
  letter-spacing: -0.02em;
  overflow-wrap: break-word; }
  .event_header_module__title:not(:last-child) {
    margin-bottom: 1em; }
  @media all and (max-width: 767px) {
    .event_header_module__title {
      font-size: 2.88rem;
      line-height: 3.12rem; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .event_header_module__title {
      font-size: 5.76rem;
      line-height: 6.24rem; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .event_header_module__title {
      font-size: 7.68rem;
      line-height: 8.32rem; } }
  @media (min-width: 1200px) {
    .event_header_module__title {
      font-size: 9.6rem;
      line-height: 10.4rem; } }
  @media (min-width: 1681px) {
    .event_header_module__title {
      font-size: 9.6rem;
      line-height: 10.4rem; } }
  [data-exception-option="cancelled"] .event_header_module__title {
    text-decoration: line-through; }

[data-identity="1.5"] .event_header_module__title {
  font-family: 'Plato Regular', sans-serif;
  font-weight: normal;
  font-style: italic; }
