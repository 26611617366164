.press_release_header_module__type {
  margin: 0;
  padding: 0;
  font-family: 'Plato Text', sans-serif;
  font-weight: normal;
  letter-spacing: 0.04em;
  display: inline-block; }
  .press_release_header_module__type:not(:last-child) {
    margin-bottom: 1em; }
  @media all and (max-width: 767px) {
    .press_release_header_module__type {
      font-size: 1.4rem;
      line-height: 1.96rem; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .press_release_header_module__type {
      font-size: 1.6rem;
      line-height: 2.24rem; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .press_release_header_module__type {
      font-size: 1.8rem;
      line-height: 2.52rem; } }
  @media (min-width: 1200px) {
    .press_release_header_module__type {
      font-size: 2rem;
      line-height: 2.8rem; } }
  @media (min-width: 1681px) {
    .press_release_header_module__type {
      font-size: 2rem;
      line-height: 2.8rem; } }

[data-identity="1.5"] .press_release_header_module__type {
  font-family: 'Plato Regular', sans-serif;
  font-weight: normal; }
