.file_module__attachment_file {
  font-family: 'Plato Outline', sans-serif;
  font-weight: normal; }
  @media all and (max-width: 767px) {
    .file_module__attachment_file {
      font-size: 3.2rem;
      line-height: 3.6rem; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .file_module__attachment_file {
      font-size: 3.84rem;
      line-height: 4.32rem; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .file_module__attachment_file {
      font-size: 5.12rem;
      line-height: 5.76rem; } }
  @media (min-width: 1200px) {
    .file_module__attachment_file {
      font-size: 5.12rem;
      line-height: 5.76rem; } }
  @media (min-width: 1681px) {
    .file_module__attachment_file {
      font-size: 6.4rem;
      line-height: 7.2rem; } }
  .file_module__content[data-label-size='large'] .file_module__attachment_file {
    letter-spacing: -0.02em; }
    @media all and (max-width: 767px) {
      .file_module__content[data-label-size='large'] .file_module__attachment_file {
        font-size: 2.88rem;
        line-height: 3.12rem; } }
    @media (min-width: 768px) and (max-width: 991px) {
      .file_module__content[data-label-size='large'] .file_module__attachment_file {
        font-size: 5.76rem;
        line-height: 6.24rem; } }
    @media (min-width: 992px) and (max-width: 1199px) {
      .file_module__content[data-label-size='large'] .file_module__attachment_file {
        font-size: 7.68rem;
        line-height: 8.32rem; } }
    @media (min-width: 1200px) {
      .file_module__content[data-label-size='large'] .file_module__attachment_file {
        font-size: 9.6rem;
        line-height: 10.4rem; } }
    @media (min-width: 1681px) {
      .file_module__content[data-label-size='large'] .file_module__attachment_file {
        font-size: 9.6rem;
        line-height: 10.4rem; } }
  .file_module__attachment_file a {
    color: black;
    text-decoration: none; }
    .file_module__attachment_file a:hover {
      font-family: 'Plato Basic', sans-serif;
      font-weight: normal; }

[data-identity="1.5"] .file_module__attachment_file {
  font-family: 'Plato Regular', sans-serif;
  font-weight: normal;
  font-style: italic; }
  [data-identity="1.5"] .file_module__attachment_file a:hover {
    font-family: 'Plato Regular', sans-serif;
    font-weight: normal;
    font-style: normal; }
