@charset "UTF-8";
.search_results_section_component__root {
  margin: 0; }

.search_results_section_component__header {
  text-align: center;
  text-transform: lowercase; }
  @media all and (max-width: 767px) {
    .search_results_section_component__header {
      font-size: 3.2rem;
      line-height: 3.6rem; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .search_results_section_component__header {
      font-size: 3.84rem;
      line-height: 4.32rem; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .search_results_section_component__header {
      font-size: 5.12rem;
      line-height: 5.76rem; } }
  @media (min-width: 1200px) {
    .search_results_section_component__header {
      font-size: 5.12rem;
      line-height: 5.76rem; } }
  @media (min-width: 1681px) {
    .search_results_section_component__header {
      font-size: 6.4rem;
      line-height: 7.2rem; } }

.search_results_section_component__list {
  list-style: none;
  margin: 0;
  padding: 0; }
  .search_results_section_component__list > li {
    margin: 0;
    padding: 0; }

.search_results_section_component__item mark {
  background-color: #fffa00;
  font-style: inherit; }

.search_results_section_component__item:not(:last-child) {
  margin-bottom: 1em; }

.search_results_section_component__link {
  color: inherit;
  text-decoration: none; }

.search_results_section_component__title {
  letter-spacing: 0.02em; }
  @media all and (max-width: 767px) {
    .search_results_section_component__title {
      font-size: 1.68rem;
      line-height: 2.16rem; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .search_results_section_component__title {
      font-size: 1.96rem;
      line-height: 2.52rem; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .search_results_section_component__title {
      font-size: 2.52rem;
      line-height: 3.24rem; } }
  @media (min-width: 1200px) {
    .search_results_section_component__title {
      font-size: 2.8rem;
      line-height: 3.6rem; } }
  @media (min-width: 1681px) {
    .search_results_section_component__title {
      font-size: 2.8rem;
      line-height: 3.6rem; } }

.search_results_section_component__body {
  letter-spacing: 0.025em; }
  @media all and (max-width: 767px) {
    .search_results_section_component__body {
      font-size: 1.12rem;
      line-height: 1.6rem; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .search_results_section_component__body {
      font-size: 1.12rem;
      line-height: 1.6rem; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .search_results_section_component__body {
      font-size: 1.4rem;
      line-height: 2rem; } }
  @media (min-width: 1200px) {
    .search_results_section_component__body {
      font-size: 1.4rem;
      line-height: 2rem; } }
  @media (min-width: 1681px) {
    .search_results_section_component__body {
      font-size: 1.4rem;
      line-height: 2rem; } }
  .search_results_section_component__body em {
    font-style: normal; }
  .search_results_section_component__body mark {
    background-color: #fffa00;
    font-style: inherit; }
  .search_results_section_component__body span:before {
    content: " … "; }
  .search_results_section_component__body span:after {
    content: " … "; }
  .search_results_section_component__body span:not(:last-child):after {
    content: ""; }

.search_results_section_component__details {
  letter-spacing: 0.025em; }
  @media all and (max-width: 767px) {
    .search_results_section_component__details {
      font-size: 1.12rem;
      line-height: 1.6rem; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .search_results_section_component__details {
      font-size: 1.12rem;
      line-height: 1.6rem; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .search_results_section_component__details {
      font-size: 1.4rem;
      line-height: 2rem; } }
  @media (min-width: 1200px) {
    .search_results_section_component__details {
      font-size: 1.4rem;
      line-height: 2rem; } }
  @media (min-width: 1681px) {
    .search_results_section_component__details {
      font-size: 1.4rem;
      line-height: 2rem; } }
  .search_results_section_component__details .day.a,
  .search_results_section_component__details .day.a + .sep {
    display: none; }

.search_results_section_component__date_time_range .current_year .month.m + .sep,
.search_results_section_component__date_time_range .current_year .year.Y {
  display: none; }

.search_results_section_component__date_time_range .date_range:not(.current_year) .current_year .month.m + .sep,
.search_results_section_component__date_time_range .date_range:not(.current_year) .current_year .year.Y {
  display: initial; }

.search_results_section_component__date_time_range .same_year .from .month.m + .sep,
.search_results_section_component__date_time_range .same_year .from .year.Y {
  display: none; }

.search_results_section_component__date_time_range .same_year.same_month .from .day.d + .sep,
.search_results_section_component__date_time_range .same_year.same_month .from .month,
.search_results_section_component__date_time_range .same_year.same_month .from .month + .sep {
  display: none; }

.search_results_section_component__date_range .current_year .month.m + .sep,
.search_results_section_component__date_range .current_year .year.Y {
  display: none; }

.search_results_section_component__date_range .date_range:not(.current_year) .current_year .month.m + .sep,
.search_results_section_component__date_range .date_range:not(.current_year) .current_year .year.Y {
  display: initial; }

.search_results_section_component__date_range .same_year .from .month.m + .sep,
.search_results_section_component__date_range .same_year .from .year.Y {
  display: none; }

.search_results_section_component__date_range .same_year.same_month .from .day.d + .sep,
.search_results_section_component__date_range .same_year.same_month .from .month,
.search_results_section_component__date_range .same_year.same_month .from .month + .sep {
  display: none; }

.search_results_section_component__date .current_year .month.m + .sep,
.search_results_section_component__date .current_year .year.Y {
  display: none; }

.search_results_section_component__date .date_range:not(.current_year) .current_year .month.m + .sep,
.search_results_section_component__date .date_range:not(.current_year) .current_year .year.Y {
  display: initial; }
