.event_header_module__link_to__parent {
  margin: 0;
  padding: 0;
  font-family: 'Plato Outline', sans-serif;
  font-weight: normal;
  letter-spacing: 0.04em;
  display: inline-block;
  margin-right: 0.5em; }
  .event_header_module__link_to__parent:not(:last-child) {
    margin-bottom: 1em; }
  @media all and (max-width: 767px) {
    .event_header_module__link_to__parent {
      font-size: 1.4rem;
      line-height: 1.96rem; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .event_header_module__link_to__parent {
      font-size: 1.6rem;
      line-height: 2.24rem; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .event_header_module__link_to__parent {
      font-size: 1.8rem;
      line-height: 2.52rem; } }
  @media (min-width: 1200px) {
    .event_header_module__link_to__parent {
      font-size: 2rem;
      line-height: 2.8rem; } }
  @media (min-width: 1681px) {
    .event_header_module__link_to__parent {
      font-size: 2rem;
      line-height: 2.8rem; } }
  .event_header_module__link_to__parent a {
    color: black;
    text-decoration: none; }
    .event_header_module__link_to__parent a:hover {
      font-family: 'Plato Text', sans-serif;
      font-weight: normal; }

[data-identity="1.5"] .event_header_module__link_to__parent {
  font-family: 'Plato Regular', sans-serif;
  font-weight: normal; }
