@charset "UTF-8";
.community_list_module__thumbnails {
  list-style: none;
  margin: 0;
  padding: 0; }
  .community_list_module__thumbnails > li {
    margin: 0;
    padding: 0; }
  .community_list_module__thumbnails .community_page__thumbnail {
    font-family: 'Plato Regular', sans-serif;
    font-weight: normal;
    letter-spacing: -0.02em;
    display: inline;
    font-style: italic; }
    @media all and (max-width: 767px) {
      .community_list_module__thumbnails .community_page__thumbnail {
        font-size: 2.88rem;
        line-height: 3.12rem; } }
    @media (min-width: 768px) and (max-width: 991px) {
      .community_list_module__thumbnails .community_page__thumbnail {
        font-size: 5.76rem;
        line-height: 6.24rem; } }
    @media (min-width: 992px) and (max-width: 1199px) {
      .community_list_module__thumbnails .community_page__thumbnail {
        font-size: 7.68rem;
        line-height: 8.32rem; } }
    @media (min-width: 1200px) {
      .community_list_module__thumbnails .community_page__thumbnail {
        font-size: 9.6rem;
        line-height: 10.4rem; } }
    @media (min-width: 1681px) {
      .community_list_module__thumbnails .community_page__thumbnail {
        font-size: 9.6rem;
        line-height: 10.4rem; } }
    .community_list_module__thumbnails .community_page__thumbnail:not(:last-child):after {
      font-family: 'Plato Regular', sans-serif;
      font-weight: normal;
      content: " • "; }
    .community_list_module__thumbnails .community_page__thumbnail a {
      text-decoration: none;
      color: black; }
      .community_list_module__thumbnails .community_page__thumbnail a:hover {
        font-style: normal; }
