@charset "UTF-8";
.event_list_header_module__tags {
  list-style: none;
  margin: 0;
  padding: 0;
  font-family: 'Plato Regular', sans-serif;
  font-weight: normal;
  letter-spacing: 0.02em;
  background-color: black;
  display: inline-flex;
  flex-direction: column;
  padding: .5em;
  padding-top: 1em;
  padding-bottom: 1em; }
  .event_list_header_module__tags > li {
    margin: 0;
    padding: 0; }
  @media all and (max-width: 767px) {
    .event_list_header_module__tags {
      font-size: 1.68rem;
      line-height: 2.16rem; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .event_list_header_module__tags {
      font-size: 1.96rem;
      line-height: 2.52rem; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .event_list_header_module__tags {
      font-size: 2.52rem;
      line-height: 3.24rem; } }
  @media (min-width: 1200px) {
    .event_list_header_module__tags {
      font-size: 2.8rem;
      line-height: 3.6rem; } }
  @media (min-width: 1681px) {
    .event_list_header_module__tags {
      font-size: 2.8rem;
      line-height: 3.6rem; } }

.event_list_header_module__tag {
  font-family: 'Plato Regular', sans-serif;
  font-weight: normal;
  letter-spacing: 0.02em; }
  @media all and (max-width: 767px) {
    .event_list_header_module__tag {
      font-size: 1.68rem;
      line-height: 2.16rem; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .event_list_header_module__tag {
      font-size: 1.96rem;
      line-height: 2.52rem; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .event_list_header_module__tag {
      font-size: 2.52rem;
      line-height: 3.24rem; } }
  @media (min-width: 1200px) {
    .event_list_header_module__tag {
      font-size: 2.8rem;
      line-height: 3.6rem; } }
  @media (min-width: 1681px) {
    .event_list_header_module__tag {
      font-size: 2.8rem;
      line-height: 3.6rem; } }
  .event_list_header_module__tag a {
    color: #fffa00;
    text-decoration: none; }
    [data-past] .event_list_header_module__tag a {
      color: #afafaf; }
    .event_list_header_module__tag a:after {
      color: black;
      content: " …"; }
    .event_list_header_module__tag a:hover {
      font-family: 'Plato Regular', sans-serif;
      font-weight: normal; }
      .event_list_header_module__tag a:hover:after {
        color: #fffa00; }
        [data-past] .event_list_header_module__tag a:hover:after {
          color: #afafaf; }

.event_list_header_module__tag__is_current {
  display: none; }
