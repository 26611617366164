@media all and (max-width: 767px) {
  [class*='module__content'],
  [class*='module__form'] {
    padding: 1.6rem;
    padding-bottom: 3.2rem; } }

@media (min-width: 768px) and (max-width: 991px) {
  [class*='module__content'],
  [class*='module__form'] {
    padding: 2.24rem;
    padding-bottom: 4.48rem; } }

@media (min-width: 992px) and (max-width: 1199px) {
  [class*='module__content'],
  [class*='module__form'] {
    padding: 2.52rem;
    padding-bottom: 5.04rem; } }

@media (min-width: 1200px) {
  [class*='module__content'],
  [class*='module__form'] {
    padding: 2.8rem;
    padding-bottom: 5.6rem; } }

@media (min-width: 1681px) {
  [class*='module__content'],
  [class*='module__form'] {
    padding: 2.8rem;
    padding-bottom: 5.6rem; } }

div:not(.modulor_web_module__is_edit_mode) .analytics_module, div:not(.modulor_web_module__is_edit_mode)
.column_module, div:not(.modulor_web_module__is_edit_mode)
.expandable_section_module, div:not(.modulor_web_module__is_edit_mode)
.menu_module, div:not(.modulor_web_module__is_edit_mode)
.soft_tabs_container_module, div:not(.modulor_web_module__is_edit_mode)
.yield_module {
  padding: 0; }

div:not(.modulor_web_module__is_edit_mode) .flash_module {
  padding-top: 0;
  padding-bottom: 0; }

@media all and (max-width: 767px) {
  div:not(.modulor_web_module__is_edit_mode) .footer_module {
    padding-left: 2.4rem;
    padding-right: 2.4rem; } }

@media (min-width: 768px) and (max-width: 991px) {
  div:not(.modulor_web_module__is_edit_mode) .footer_module {
    padding-left: 3.36rem;
    padding-right: 3.36rem; } }

@media (min-width: 992px) and (max-width: 1199px) {
  div:not(.modulor_web_module__is_edit_mode) .footer_module {
    padding-left: 3.78rem;
    padding-right: 3.78rem; } }

@media (min-width: 1200px) {
  div:not(.modulor_web_module__is_edit_mode) .footer_module {
    padding-left: 4.2rem;
    padding-right: 4.2rem; } }

@media (min-width: 1681px) {
  div:not(.modulor_web_module__is_edit_mode) .footer_module {
    padding-left: 4.2rem;
    padding-right: 4.2rem; } }

@media all and (max-width: 767px) {
  div:not(.modulor_web_module__is_edit_mode) .footer_module {
    padding-left: 1.6rem;
    padding-right: 1.6rem; } }

@media (min-width: 768px) and (max-width: 991px) {
  div:not(.modulor_web_module__is_edit_mode) .footer_module {
    padding-left: 2.24rem;
    padding-right: 2.24rem; } }
